import Send from '../../trans.client.js'

export default {
    //CA 내역 조회
    getCaHistory (formData) {
        return Send({
          method: 'get',
          url: '/trans/ca/' + formData.blNo,
          params: {
            caNo: formData.caNo
          }
        }).catch((err) => {
          console.log(err)
        })
    },
    getCaReqInfo (blNo) {
      return Send({
        method: 'get',
        url: '/trans/ca/' + blNo + '/request'
      }).catch((err) => {
        console.log(err)
      })
    },
    updatePay (params) {
      return Send({
        method: 'put',
        url: '/trans/ca/' + params.blNo + '/request/pay',
        data: params
      }).catch((err) => {
        console.log(err)
      })
    },
    insertCaReq (params) {
      return Send({
        method: 'post',
        url: '/trans/ca/' + params.blNo + '/request',
        data: params
      }).catch((err) => {
        console.log(err)
      })
    },
    deleteCaReq (blNo) {
      return Send({
        method: 'delete',
        url: '/trans/ca/' + blNo + '/request'
      }).catch((err) => {
        console.log(err)
      })
    },
    findCaRequestAttach (blNo) {
      return Send({
        method: 'get',
        url: '/trans/ca/' + blNo + '/attach'
      }).catch((err) => {
        console.log(err)
      })
    },
    insertCaRequestAttach (params) {
      return Send({
        method: 'post',
        url: '/trans/ca/' + params.blNo + '/attach',
        data: params
      }).catch((err) => {
        console.log(err)
      })
    }
}
